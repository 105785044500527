import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BASE_URL } from '../utils/baseURL';
import { AppContext } from './app.context';

AppProvider.propTypes = {
  children: PropTypes.node,
};

export default function AppProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInAppLoading, setIsInAppLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [info, setInfo] = useState(null);
  const [warn, setWarning] = useState(null);
  const [appUsers, setAppUsers] = useState([]);
  const [appPosts, setAppPosts] = useState([]);
  const [metrics, setMetrics] = useState({
    transactionTotal: {
      total: 0,
      ours: 0,
      theirs: 0,
      paidout: 0,
    },
    totalUsers: 0,
    totalEvents: 0,
    totalCharts: 0,
    totalTickets: 0,
    transactionData: {
      votes: [],
      tickets: [],
    },
    allTransactions: [],
    withdrawals: [],
  });

  useEffect(() => {
    setIsLoading(true);
    const isLoggedIn = localStorage.getItem('id');
    if (isLoggedIn) {
      getUser();
      fetchAdminMetrics();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    toast.error(error, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }, [error]);

  useEffect(() => {
    toast.success(success, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }, [success]);

  useEffect(() => {
    toast.info(info, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }, [info]);

  useEffect(() => {
    toast.warn(warn, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }, [warn]);

  const fetchAdminMetrics = () => {
    setIsInAppLoading(true);
    const reqURL = `${BASE_URL}admin/metrics`;
    const token = localStorage.getItem('token');
    setInfo('Fetching and formatting app metrics. This might take a while');
    fetch(reqURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.metrics) {
          setMetrics(data.metrics);
        } else {
          setError(data.message);
        }
        setIsInAppLoading(false);
      })
      .catch((e) => {
        setError('An error ocurred while trying to load App Metrics', e);
        setIsInAppLoading(false);
      });
  };

  const fetchAllUsers = () => {
    setIsInAppLoading(true);
    const reqURL = `${BASE_URL}admin/fetch/users`;
    const token = localStorage.getItem('token');
    setInfo('Fetching and formating app users');
    fetch(reqURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.users) {
          setAppUsers(data.users);
        } else {
          setError(data.message);
        }
        setIsInAppLoading(false);
      })
      .catch((e) => {
        setError('An erro ocured while trying to fetch App Users', e);
        setIsInAppLoading(false);
      });
  };

  const fetchAllPosts = () => {
    setIsInAppLoading(true);
    const reqURL = `${BASE_URL}admin/fetch/posts`;
    const token = localStorage.getItem('token');
    setInfo('Fetching and formating app posts');
    fetch(reqURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.posts) {
          setAppPosts(data.posts);
        } else {
          setError(data.message);
        }
        setIsInAppLoading(false);
      })
      .catch((e) => {
        setError('An erro ocured while trying to fetch App Posts', e);
        setIsInAppLoading(false);
      });
  };

  const verifyUser = (userId, value) => {
    setIsInAppLoading(true);

    let reqURL = `${BASE_URL}admin/verify/user/${userId}`;
    if (value) {
      reqURL = `${BASE_URL}admin/unverify/user/${userId}`;
    }
    const token = localStorage.getItem('token');
    fetch(reqURL, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.user) {
          setAppUsers(
            appUsers.map((x) => {
              if (x.id === data.user.id) return data.user;
              return x;
            })
          );
          setSuccess(data.message);
        } else {
          setError(data.message);
        }
        setIsInAppLoading(false);
      })
      .catch((e) => {
        setError('An error occured while trying to verify user', e);
        setIsInAppLoading(false);
      });
  };

  const blockUser = (userId, value) => {
    setIsInAppLoading(true);
    let reqURL = `${BASE_URL}admin/block/user/${userId}`;
    if (value) {
      reqURL = `${BASE_URL}admin/unblock/user/${userId}`;
    }
    const token = localStorage.getItem('token');
    fetch(reqURL, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.user) {
          setAppUsers(
            appUsers.map((x) => {
              if (x.id === data.user.id) return data.user;
              return x;
            })
          );
          setSuccess(data.message);
        } else {
          setError(data.message);
        }
        setIsInAppLoading(false);
      })
      .catch((e) => {
        setError('An error occured while trying to block user', e);
        setIsInAppLoading(false);
      });
  };

  const blockPost = (postId, value) => {
    setIsInAppLoading(true);
    let reqURL = `${BASE_URL}admin/block/post/${postId}`;
    if (value) {
      reqURL = `${BASE_URL}admin/unblock/post/${postId}`;
    }
    const token = localStorage.getItem('token');
    fetch(reqURL, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.post) {
          setAppPosts(
            appPosts.map((x) => {
              if (x.id === data.post.id) return data.post;
              return x;
            })
          );
          setSuccess(data.message);
        } else {
          setError(data.message);
        }
        setIsInAppLoading(false);
      })
      .catch((e) => {
        setError('An error occured while trying to block post', e);
        setIsInAppLoading(false);
      });
  };

  const updateWithdrawal = (withdrawalId, value) => {
    setIsInAppLoading(true);
    let reqURL;

    if (value === 'initiate') {
      reqURL = `${BASE_URL}admin/initiate/withdrawal/${withdrawalId}`;
    }

    if (value === 'approve') {
      reqURL = `${BASE_URL}admin/approve/withdrawal/${withdrawalId}`;
    }

    if (value === 'reject') {
      reqURL = `${BASE_URL}admin/reject/withdrawal/${withdrawalId}`;
    }

    if (!reqURL) {
      setError(`${value} is not a valid option this request: approve | reject | initiate`);
      return;
    }

    const token = localStorage.getItem('token');
    fetch(reqURL, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.withdrawal) {
          const newMetrics = metrics;
          newMetrics.withdrawal = data.withdrawal;
          setMetrics(newMetrics);
          setSuccess(data.message);
        } else {
          setError(data.message);
        }
        setIsInAppLoading(false);
      })
      .catch((e) => {
        setError(`An error occured while trying to ${value} this request`, e);
        setIsInAppLoading(false);
      });
  };

  const makeAdmin = (userId, value) => {
    setIsInAppLoading(true);
    let reqURL = `${BASE_URL}admin/make/user/admin/${userId}`;
    if (value) {
      reqURL = `${BASE_URL}admin/remove/user/admin/${userId}`;
    }
    const token = localStorage.getItem('token');
    fetch(reqURL, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.user) {
          setAppUsers(
            appUsers.map((x) => {
              if (x.id === data.user.id) return data.user;
              return x;
            })
          );
          setSuccess(data.message);
        } else {
          setError(data.message);
        }
        setIsInAppLoading(false);
      })
      .catch((e) => {
        setError('An error occured while trying to make user admin', e);
        setIsInAppLoading(false);
      });
  };

  const login = async (email, password) => {
    const reqURL = `${BASE_URL}users/web-login`;
    const body = {
      email,
      password,
    };
    console.log(`Logging in with --==> ${reqURL}`);
    try {
      setIsLoading(true);
      fetch(reqURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.user) {
            if (data.user.isAdmin) {
              const userInfo = data.user;
              const { token } = data;
              setUser(userInfo);
              localStorage.setItem('token', token);
              localStorage.setItem('isLoggedIn', true);
              localStorage.setItem('id', userInfo.id);
              setSuccess(`Welcome back, admin ${userInfo.name}`);
            } else {
              setError('You are not an admin and not authorized for access');
            }
          } else {
            setError(data.message);
          }
          setIsLoading(false);
        })

        .catch((e) => {
          setIsLoading(false);
          setError(e);
        });
    } catch (error) {
      setError(error);
    }
  };

  const socialLogin = (userData) => {
    const reqURL = `${BASE_URL}auth/google/null`;

    console.log(`Logging in => ${reqURL}`);

    fetch(reqURL, {
      method: 'GET',
      body: JSON.stringify(userData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const userInfo = data.user;
        if (userInfo.isAdmin) {
          const { token } = data;
          setUser(userInfo);
          localStorage.setItem('token', token);
          localStorage.setItem('isLoggedIn', true);
          localStorage.setItem('id', userInfo.id);
        } else {
          setError('You do not have admin rights to login to this platform');
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e);
      });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('id');
    setUser(null);
    setSuccess(`Goodbye ${user.name}, See you soon`);
  };

  const getUser = async () => {
    console.log('fetching user');
    // setIsLoading(true);
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');
    try {
      fetch(`${BASE_URL}users/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUser(data.user);
          setIsLoading(false);
        });
    } catch (error) {
      setError(error);
    } finally {
      //   setIsLoading(false);
    }
  };

  return (
    <AppContext.Provider
      value={{
        user,
        isLoading,
        isInAppLoading,
        metrics,
        appUsers,
        appPosts,
        login,
        logout,
        socialLogin,
        setError,
        setSuccess,
        setInfo,
        setWarning,
        fetchAllUsers,
        verifyUser,
        blockUser,
        blockPost,
        makeAdmin,
        fetchAllPosts,
        updateWithdrawal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
