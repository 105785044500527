// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Post',
    path: '/dashboard/post',
    icon: icon('ic_post'),
  },
  {
    title: 'Transactions',
    path: '/dashboard/transaction',
    icon: icon('ic_transaction'),
  },
  {
    title: 'Withdrawals',
    path: '/dashboard/withdrawal',
    icon: icon('ic_withdrawal'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },
];

export default navConfig;
