import React from 'react';
import './loader.css';

export const Loader = () => (
  <div className="loading-screen">
    <div className="loading-overlay" />
    <div className="loading-content">
      <div className="loader" />
      <h2>Loading...</h2>
    </div>
  </div>
);
