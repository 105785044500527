import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/app.context';
// sections
import {
  AppNewsUpdate,
  AppOrderTimeline,
  NtoporCurrentFunds,
  AppWebsiteVisits,
  AppWidgetSummary,
} from '../sections/@dashboard/app';
import { fNumber } from '../utils/formatNumber';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const { metrics } = useContext(AppContext);
  const [labels, setLables] = useState([]);
  const [total, setTotal] = useState(0);
  const [ours, setOurs] = useState(0);
  const [theirs, setTheirs] = useState(0);
  const [paidout, setPaidOut] = useState(0);
  const [chartData, setChartData] = useState([
    {
      name: 'Empty',
      type: 'area',
      fill: 'gradient',
      data: [],
    },
  ]);
  const [transactionUpdates, setTransactionUpdates] = useState([
    {
      id: faker.datatype.uuid(),
      title: faker.name.jobTitle(),
      description: faker.name.jobTitle(),
      image: `/assets/images/covers/cover_${1}.jpg`,
      postedAt: faker.date.recent(),
    },
  ]);
  const [withdrawalMetrics, setWIthdrawalMetrics] = useState([
    {
      id: faker.datatype.uuid(),
      title: 'No withdrawals have been done',
      type: `order${1}`,
      time: faker.date.past(),
      status: 'Pending',
    },
  ]);

  const replaceAtIndex = (list, index, newData) => {
    if (index >= 0 && index < list.length) {
      list[index] = newData;
    }
    return list;
  };

  const formatDate = (date) => {
    const transactionDate = new Date(date);
    return transactionDate.toISOString().split('T')[0]; // Extracts the day in 'YYYY-MM-DD' format
  };

  const uniqueArray = (array) => array.filter((value, index, array) => array.indexOf(value) === index);

  useEffect(() => {
    const { transactionData, allTransactions, transactionTotal, withdrawals } = metrics;
    const dates = [];

    console.log(transactionTotal);

    setTotal(transactionTotal.total);
    setOurs(transactionTotal.ours);
    setTheirs(transactionTotal.theirs);
    setPaidOut(transactionTotal.paidout);

    transactionData.votes.forEach((transaction) => {
      dates.push(formatDate(transaction.dateCreated));
    });
    transactionData.tickets.forEach((transaction) => {
      dates.push(formatDate(transaction.dateCreated));
    });

    const finDates = uniqueArray(dates);

    const votesData = Array(finDates.length).fill(0);
    const votesAmountByDay = {};
    transactionData.votes.forEach((transaction) => {
      const dayKey = formatDate(transaction.dateCreated);

      if (!votesAmountByDay[dayKey]) {
        votesAmountByDay[dayKey] = 0;
      }

      votesAmountByDay[dayKey] += parseInt(transaction.amount, 10);
    });

    const ticketsData = Array(finDates.length).fill(0);
    const ticketsAmountByDay = {};
    transactionData.tickets.forEach((transaction) => {
      const dayKey = formatDate(transaction.dateCreated);

      if (!ticketsAmountByDay[dayKey]) {
        ticketsAmountByDay[dayKey] = 0;
      }

      ticketsAmountByDay[dayKey] += parseInt(transaction.amount, 10);
    });

    const sortedFinDates = finDates.sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA - dateB;
    });

    sortedFinDates.map((x, index) => {
      console.log(x, index);
      replaceAtIndex(votesData, index, votesAmountByDay[x] || 0);
      replaceAtIndex(ticketsData, index, ticketsAmountByDay[x] || 0);
      return x;
    });

    setLables(sortedFinDates);
    setChartData([
      {
        name: 'Votes',
        type: 'area',
        fill: 'gradient',
        data: votesData,
      },
      {
        name: 'Tickets',
        type: 'line',
        fill: 'solid',
        data: ticketsData,
      },
    ]);

    if (allTransactions && allTransactions.length > 0) {
      const transactionFormatted = [];
      allTransactions.forEach((transaction) => {
        const imageIndex = transaction.type === 'Ticket' ? 1 : 2;
        transactionFormatted.push({
          id: faker.datatype.uuid(),
          title: `${transaction.type} Purchase`,
          description: `Purchase of ${transaction.type} by ${transaction.user.name} for XAF ${fNumber(
            transaction.amount
          )}`,
          image: `/assets/images/covers/cover_${imageIndex}.jpg`,
          postedAt: new Date(transaction.dateCreated),
        });
      });
      setTransactionUpdates(transactionFormatted.slice(0, 10));
    }

    if (withdrawals && withdrawals.length > 0) {
      const withrawalsFormatted = [];
      withdrawals.forEach((withdrawal) => {
        withrawalsFormatted.push({
          id: withdrawal._id,
          title: `Withdrawal of ${withdrawal.amount} by ${withdrawal.user.name}`,
          type: withdrawal.meta_type,
          time: withdrawal?.dateApproved ? new Date(withdrawal.dateApproved) : new Date(withdrawal.dateInitiated),
          status: withdrawal.status,
        });
      });
      setWIthdrawalMetrics(withrawalsFormatted.slice(0, 10));
    }

    return () => {
      setLables([]);
      setChartData([]);
    };
  }, [metrics]);

  return (
    <>
      <Helmet>
        <title> Dashboard | Ntopor </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Users" total={metrics.totalUsers} icon={'ant-design:user-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="All Events"
              total={metrics.totalEvents}
              color="info"
              icon={'ic:outline-event-note'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="All Charts" total={metrics.totalCharts} color="warning" icon={'game-icons:vote'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="All Tickets"
              total={metrics.totalTickets}
              color="error"
              icon={'icon-park-outline:ticket'}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="App Activities"
              subheader="Shows the activity timeline for purchasing of votes and tickets"
              chartLabels={labels}
              chartData={chartData}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <NtoporCurrentFunds
              title="Current Funds"
              subheader="Shows the current Funds"
              chartData={[
                { label: 'Total', value: parseInt((total / total) * 100, 10) },
                { label: 'Ntopor', value: parseInt((ours / total) * 100, 10) },
                { label: 'Clients', value: parseInt((theirs / total) * 100, 10) },
                { label: 'Paid Out', value: parseInt((paidout / total) * 100, 10) },
              ]}
              chartValues={[
                { label: 'Total', value: total },
                { label: 'Ntopor', value: ours },
                { label: 'Clients', value: theirs },
                { label: 'Paid Out', value: paidout },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate title="Transaction Updates" list={transactionUpdates} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline title="Withdrawal Timeline" list={withdrawalMetrics} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
