import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
import { AppContext } from '../context/app.context';
import { fNumber } from '../utils/formatNumber';
import { AppNewsUpdate } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function TransactionPage() {
  const { metrics } = useContext(AppContext);
  const [transactionUpdates, setTransactionUpdates] = useState([
    {
      id: faker.datatype.uuid(),
      title: faker.name.jobTitle(),
      description: faker.name.jobTitle(),
      image: `/assets/images/covers/cover_${1}.jpg`,
      postedAt: faker.date.recent(),
    },
  ]);

  useEffect(() => {
    const { allTransactions } = metrics;

    if (allTransactions && allTransactions.length > 0) {
      const transactionFormatted = [];
      allTransactions.forEach((transaction) => {
        const imageIndex = transaction.type === 'Ticket' ? 1 : 2;
        transactionFormatted.push({
          id: faker.datatype.uuid(),
          title: `${transaction.type} Purchase`,
          description: `Purchase of ${transaction.type} by ${transaction.user.name} for XAF ${fNumber(
            transaction.amount
          )}`,
          image: `/assets/images/covers/cover_${imageIndex}.jpg`,
          postedAt: new Date(transaction.dateCreated),
        });
      });
      setTransactionUpdates(transactionFormatted.slice(0, 10));
    }
  }, [metrics]);
  return (
    <>
      <Helmet>
        <title> Transaction | Ntopor Admin </title>
      </Helmet>

      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <AppNewsUpdate title="All Transactions" list={transactionUpdates} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
